import React from 'react';
import { useDispatch } from 'react-redux';
import AppActions from '../../store/actions/appActions';

export default class AppHooks {
  static useLocale() {
    const dispatch = useDispatch();

    React.useEffect(() => {
      dispatch(AppActions.getLocale());
    }, [dispatch]);
  }

  static useConsoleReport() {
    React.useEffect(() => {
      console.group('Федерация подводного спорта Томской области - ФПСТО');
      console.log(`Version ${process.env.REACT_APP_VERSION}`);
      console.log('SoftInform LLC. 2019-2020');
      console.groupEnd();
    }, []);
  }
}
