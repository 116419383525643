import axios from 'axios';
import LocalStorage from '../storage/localStorage';
import StorageManagement from '../storage/storageManagement';
import Router from '../router';
import RouterPaths from '../../constants/routerPaths';
import { dispatch } from '../../index';
import AppActions from '../../store/actions/appActions';
import { ls } from '../locale/locale';

const stands = {
  dev: process.env.REACT_APP_DEV_STAND_URL || 'http://127.0.0.1:8000/',
  prod: process.env.REACT_APP_PROD_STAND_URL || 'http://127.0.0.1:8000/',
};

export const StandUrl = process.env.NODE_ENV !== 'production' ? stands.dev : stands.prod;

const apiPath = 'api';

const http = axios.create({
  baseURL: StandUrl + apiPath,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

http.interceptors.request.use(
  (config) => {
    const token = LocalStorage.token.get();

    if (config.withCredentials && token != null) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `JWT ${token}`,
        },
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403 || error.response?.status === 401) {
      StorageManagement.removeSensitiveData();
      dispatch(AppActions.clearAppData());
      Router.navigateTo(RouterPaths.indexPage);
      alert(ls('alerts/common/unauthorized'));
    }
    return Promise.reject(error);
  }
);

export default http;
