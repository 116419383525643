import FetchConstants from '../../constants/fetchConstants';
import { Competition } from '../../types/store/competition';
import { CompetitionActionTypes } from '../actions/competitionActions';
import { DataTypes } from '../../types/dataTypes';

const initialState: Competition.State = {
  data: {} as DataTypes.CompetitionVerbose,
  fetchStatus: FetchConstants.STALLING,
  files: [],
  filesFetchStatus: FetchConstants.STALLING,
};

export default function competitionReducer(state = initialState, action: Competition.ActionTypes) {
  switch (action.type) {
    case CompetitionActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case CompetitionActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case CompetitionActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    case CompetitionActionTypes.CLEAR_DATA:
      return {
        ...state,
        data: {} as DataTypes.CompetitionVerbose,
      };
    case CompetitionActionTypes.FETCH_FILES_PENDING:
      return {
        ...state,
        filesFetchStatus: FetchConstants.PENDING,
      };
    case CompetitionActionTypes.FETCH_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
        filesFetchStatus: FetchConstants.SUCCESS,
      };
    case CompetitionActionTypes.FETCH_FILES_FAILURE:
      return {
        ...state,
        filesFetchStatus: FetchConstants.FAILURE,
      };
    case CompetitionActionTypes.CLEAR_FILES_DATA:
      return {
        ...state,
        files: [],
      };
    default:
      return state;
  }
}
