import http from './apiConfig';
import { DataTypes } from '../../types/dataTypes';

export default class Api {
  static getLocale() {
    return http.get('locale/');
  }

  static getLocaleFile(url: string) {
    return http.get(url, { url, withCredentials: false });
  }

  /**
   * Auth-related API
   */

  static getToken(body: DataTypes.AuthCredentials) {
    return http.post('auth/token/', body);
  }

  static getUserInfo() {
    return http.get('auth/me/');
  }

  /**
   * Competition-related API
   */

  static competitionConfig = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  static getCompetition(competitionId: DataTypes.Id) {
    return http.get(`competition/${competitionId}/`);
  }

  static getCompetitionFiles(competitionId: DataTypes.Id) {
    return http.get(`file-competition/${competitionId}/`);
  }

  static getCompetitionZipFile(competitionId: DataTypes.Id) {
    return http.get(`competition-zipfile/${competitionId}/`, { responseType: 'blob' });
  }

  static getCompetitionFilePath(fileId: DataTypes.Id) {
    return http.get(`download-competition/${fileId}/`);
  }

  static deleteCompetitionFile(fileId: DataTypes.Id) {
    return http.delete(`download-competition/${fileId}/`);
  }

  static getCompetitionTypes() {
    return http.get(`get-competition-types/`);
  }

  static getCompetitions() {
    return http.get(`competitions-list/`, { withCredentials: false });
  }

  static postCompetition(body: FormData) {
    return http.post(`competition/`, body, Api.competitionConfig);
  }

  static postCompetitionFiles(id: DataTypes.Id, body: FormData) {
    return http.post(`file-competition/${id}/`, body, Api.competitionConfig);
  }

  static patchCompetition(competitionId: DataTypes.Id, body: FormData) {
    return http.patch(`competition/${competitionId}/`, body, Api.competitionConfig);
  }

  static deleteCompetition(competitionId: DataTypes.Id) {
    return http.delete(`competition/${competitionId}/`);
  }

  /**
   * Subject-related API
   */

  static getSubjects() {
    return http.get(`get-subjects/`);
  }

  static getSubjectsByType(type: string | number) {
    return http.get(`get-subjects/${type}/`);
  }

  static getSubjectsTakePart(competitionId: DataTypes.Id) {
    return http.get(`subjects-take-part/${competitionId}/`);
  }

  /**
   * Sportsman-related API
   */

  static getAthletesByEvent(eventId: DataTypes.Id) {
    return http.get(`sportsman-by-event/${eventId}/`);
  }

  static getAthletes() {
    return http.get('sportsman/');
  }

  static getAthlete(athleteId: DataTypes.Id) {
    return http.get(`sportsman/${athleteId}/`);
  }

  static getAthletesByTrainer(id: DataTypes.Id) {
    return http.get(`sportsman-by-trainer/${id}/`);
  }

  /**
   * Получить список спортсменов, заявленных тренером
   * @param trainerId
   */
  static getSportsmanLinkTrainer(trainerId: DataTypes.Id) {
    return http.get(`sportsman-link-trainer/${trainerId}/`);
  }

  static getAthletesBySubject(subjectId: DataTypes.Id, licenseType: number) {
    return http.get(`sportsman-by-subject/${subjectId}/${licenseType}/`);
  }

  static patchAthlete(athleteId: DataTypes.Id, body: Partial<DataTypes.Athlete>) {
    return http.patch(`sportsman/${athleteId}/`, body);
  }

  static deleteAthleteLicense(athleteId: DataTypes.Id) {
    return http.delete(`sportsman/${athleteId}`);
  }

  static postAthlete(body: DataTypes.Athlete) {
    return http.post(`sportsman/`, body);
  }

  static deleteAthlete(sportsmanId: DataTypes.Id, trainerId: DataTypes.Id) {
    return http.delete(`attach-sportsman/${sportsmanId}/${trainerId}/`);
  }

  static postAthleteDistanceResult(body: DataTypes.AthleteDistanceResult) {
    return http.post(`sportsman-link-event/`, body);
  }

  static patchAthleteEventResult(id: DataTypes.Id, body: Partial<DataTypes.AthleteLinkEvent>) {
    return http.patch(`sportsman-link-event/${id}/`, body).then((value) => value.data);
  }

  static deleteAthleteEventResult(id: DataTypes.Id) {
    return http.delete(`sportsman-link-event/${id}/`);
  }

  /**
   * Export-related API
   */

  static exportByEvent(id: DataTypes.Id, params: URLSearchParams) {
    return http.get(`export-by-distance/${id}/`, {
      responseType: 'blob',
      params,
    });
  }

  // TODO fix 'data' type; cleanup code
  static exportBySubject(
    data: any[] | any,
    id: DataTypes.Id,
    competitionType = -1,
    isTrainer = false
  ) {
    const typeOfData = competitionType === 1 ? 'subjects' : 'trainers';
    const params = new URLSearchParams();
    params.append('competitionId', id.toString());
    if (isTrainer) {
      params.append('accepted', 'False');
    }
    if (Array.isArray(data)) {
      data.forEach((item) => {
        params.append(typeOfData, item);
      });
    } else {
      params.append(typeOfData, data);
    }

    return http.get('export-by-subject/', {
      responseType: 'blob',
      params,
      timeout: 0,
    });
  }

  /**
   * Event-related API
   */

  static getEvents(competitionId: DataTypes.Id) {
    return http.get(`get-events/${competitionId}/`);
  }

  static getAthleteEvents(athleteId: DataTypes.Id, competitionId: DataTypes.Id) {
    return http.get(`event-list-by-sportsman/${athleteId}/${competitionId}/`);
  }

  static getEvent(id: DataTypes.Id) {
    return http.get(`event/${id}/`);
  }

  static postEvent(body: DataTypes.Event) {
    return http.post(`event/`, body);
  }

  static deleteEvent(eventId: DataTypes.Id) {
    return http.delete(`event/${eventId}/`);
  }

  static patchEvents(events: DataTypes.EventPatch[]) {
    return http.patch(`list-events/`, { events });
  }

  /**
   * Will use trainer's token to determine the user
   * @param eventId
   */
  static getEventPlacesByTrainer(eventId: DataTypes.Id) {
    return http.get(`get-places/${eventId}/`);
  }

  /**
   * Trainer-related API
   */
  static getTrainersBySubject(competition: string | number, subject: string | number) {
    return http.get(`trainers-by-subject/${competition}/${subject}/`);
  }

  /**
   * BID-related API
   */
  static postBid(body: DataTypes.Bid) {
    return http.post(`trainer-bid/`, body);
  }

  static getTrainerBidList(competitionId: string | number) {
    return http.get(`trainer-bid-list/${competitionId}/`);
  }

  static deleteBid(id: string | number) {
    return http.delete(`remove-bid/${id}/`);
  }

  /**
   * License-related API
   */
  static uploadLicenseFile(body: FormData) {
    return http.post(`load-license/`, body);
  }

  static postUploadedLicenses(body: DataTypes.UploadedLicenseProcessed[]) {
    return http.post(`add-license/`, { members: body });
  }

  static getLicenseExample() {
    return http.get(`get-license-example/`);
  }

  /**
   * Other API
   */
  static postCreateUser(id: string | number) {
    return http.post(`create-user/${id}/`);
  }

  static getSessions(competitionId: DataTypes.Id) {
    return http.get(`session/competition/${competitionId}/`);
  }

  static postSession(body: DataTypes.Session) {
    return http.post(`session/`, body);
  }

  static putSession(sessionId: DataTypes.Id, body: Partial<DataTypes.Session>) {
    return http.put(`session/${sessionId}/`, body);
  }

  static deleteSession(sessionId: string | number) {
    return http.delete(`session/${sessionId}/`);
  }

  static getRanks() {
    return http.get(`get-ranks/`);
  }

  static getClubs() {
    return http.get(`get-clubs/`);
  }

  static getNominations() {
    return http.get(`get-nominations/`);
  }

  static getCategories() {
    return http.get(`get-categories/`);
  }

  static getGenders() {
    return http.get(`get-genders/`);
  }
}
