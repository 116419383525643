import FetchConstants from '../../constants/fetchConstants';
import { Events } from '../../types/store/events';
import { EventsActionTypes } from '../actions/eventsActions';

const initialState: Events.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function eventsReducer(state = initialState, action: Events.ActionTypes) {
  switch (action.type) {
    case EventsActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case EventsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case EventsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
