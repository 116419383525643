import FetchConstants from '../../constants/fetchConstants';
import { Athlete } from '../../types/store/athlete';
import { AthleteActionTypes } from '../actions/athleteActions';
import { DataTypes } from '../../types/dataTypes';

const initialState: Athlete.State = {
  data: {} as Athlete.Data,
  fetchStatus: FetchConstants.STALLING,
  events: [],
  eventsFetchStatus: FetchConstants.STALLING,
};

export default function athleteReducer(state = initialState, action: Athlete.ActionTypes) {
  switch (action.type) {
    case AthleteActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case AthleteActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload as Athlete.Data,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case AthleteActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    case AthleteActionTypes.FETCH_EVENTS_PENDING:
      return {
        ...state,
        eventsFetchStatus: FetchConstants.PENDING,
      };
    case AthleteActionTypes.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload as DataTypes.AthleteDistance[],
        eventsFetchStatus: FetchConstants.SUCCESS,
      };
    case AthleteActionTypes.FETCH_EVENTS_FAILURE:
      return {
        ...state,
        eventsFetchStatus: FetchConstants.FAILURE,
      };
    case AthleteActionTypes.CLEAR_DATA:
      return {
        ...state,
        data: {} as Athlete.Data,
      };
    default:
      return state;
  }
}
