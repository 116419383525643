import FetchConstants from '../../constants/fetchConstants';
import { Ranks } from '../../types/store/ranks';
import { RanksActionTypes } from '../actions/ranksActions';

const initialState: Ranks.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function ranksReducer(state = initialState, action: Ranks.ActionTypes) {
  switch (action.type) {
    case RanksActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case RanksActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case RanksActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
