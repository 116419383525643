import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import FileUtils from '../../utils/fileUtils';

const entity = 'Competition';

export const CompetitionActionTypes = {
  FETCH_PENDING: `${entity}.FETCH_PENDING`,
  FETCH_SUCCESS: `${entity}.FETCH_SUCCESS`,
  FETCH_FAILURE: `${entity}.FETCH_FAILURE`,
  CLEAR_DATA: `${entity}.CLEAR_DATA`,
  FETCH_FILES_PENDING: `${entity}.FETCH_FILES_PENDING`,
  FETCH_FILES_SUCCESS: `${entity}.FETCH_FILES_SUCCESS`,
  FETCH_FILES_FAILURE: `${entity}.FETCH_FILES_FAILURE`,
  CLEAR_FILES_DATA: `${entity}.CLEAR_FILES_DATA`,
};

export default class CompetitionActions {
  static getCompetition = (competitionId: DataTypes.Id) => (dispatch: Dispatch) => {
    dispatch({ type: CompetitionActionTypes.FETCH_PENDING });

    return new Promise((resolve, reject) => {
      Api.getCompetition(competitionId).then(
        (result: AxiosResponse<DataTypes.CompetitionVerbose>) => {
          dispatch({
            type: CompetitionActionTypes.FETCH_SUCCESS,
            payload: result.data,
          });
          resolve();
        },
        (reason) => {
          console.error(reason);
          dispatch({ type: CompetitionActionTypes.FETCH_FAILURE });
          reject();
        }
      );
    });
  };

  static clearCompetitionData = () => ({
    type: CompetitionActionTypes.CLEAR_DATA,
  });

  static postCompetition = (competitionData: FormData) => () => {
    return new Promise((resolve: (newCompetitionId: DataTypes.Id) => void, reject) => {
      Api.postCompetition(competitionData).then(
        (response: AxiosResponse<DataTypes.CompetitionVerbose>) => {
          resolve(response.data.id);
        },
        (reason) => {
          console.error(reason);
          reject();
        }
      );
    });
  };

  static patchCompetition = (competitionId: string, data: FormData) => (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      Api.patchCompetition(competitionId, data).then(
        (result: AxiosResponse<DataTypes.CompetitionVerbose>) => {
          dispatch({
            type: CompetitionActionTypes.FETCH_SUCCESS,
            payload: result.data,
          });
          resolve();
        },
        (reason) => {
          console.error(reason);
          reject();
        }
      );
    });
  };

  static deleteCompetition = (competitionId: string) => () => {
    return new Promise((resolve, reject) => {
      Api.deleteCompetition(competitionId).then(
        () => resolve(),
        () => reject()
      );
    });
  };

  static getCompetitionFiles = (competitionId: string) => (dispatch: Dispatch) => {
    dispatch({ type: CompetitionActionTypes.FETCH_FILES_PENDING });

    Api.getCompetitionFiles(competitionId).then(
      (result: AxiosResponse<DataTypes.File[]>) => {
        dispatch({
          type: CompetitionActionTypes.FETCH_FILES_SUCCESS,
          payload: result.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: CompetitionActionTypes.FETCH_FILES_FAILURE });
      }
    );
  };

  static clearCompetitionFilesData = () => ({
    type: CompetitionActionTypes.CLEAR_FILES_DATA,
  });

  static getCompetitionFilePath = (fileId: string) => () => {
    return new Promise((resolve: (url: string) => void, reject) => {
      Api.getCompetitionFilePath(fileId).then(
        (response: AxiosResponse<string>) => {
          resolve(response.data);
        },
        (reason) => {
          console.error(reason);
          reject();
        }
      );
    });
  };

  static postFilesToCompetition = (competitionId: DataTypes.Id, files: FormData) => () => {
    Api.postCompetitionFiles(competitionId, files);
  };

  static deleteCompetitionFile = (fileId: string) => () => {
    Api.deleteCompetitionFile(fileId);
  };

  static getCompetitionZipFile = (competitionId: string, competitionName: string) => () => {
    return new Promise((resolve, reject) => {
      Api.getCompetitionZipFile(competitionId).then(
        (result: AxiosResponse<BlobPart>) => {
          FileUtils.downloadFile(
            result.data,
            { type: 'octet/stream' },
            'download',
            `${competitionName}.zip`
          );
          resolve();
        },
        (reason) => {
          console.error(reason);
          reject();
        }
      );
    });
  };
}
