import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteService } from '../../types/services/routeService';
import RouterPaths from '../../constants/routerPaths';
import LocalStorage from '../../services/storage/localStorage';

interface Props {
  render: RouteService.RouteComponent;
  exact: boolean;
  path: string | undefined;
}

export default function PrivateRoute(props: Props) {
  const { render: Component, path, exact } = props;

  const innerRender = () => {
    return LocalStorage.token.get() ? <Component /> : <Redirect to={RouterPaths.indexPage} />;
  };

  return <Route exact={exact} path={path} render={innerRender} />;
}
