import Api from '../../services/api';
import { DataTypes } from '../../types/dataTypes';
import { AxiosResponse } from 'axios';
import LocalStorage from '../../services/storage/localStorage';
import RouterPaths from '../../constants/routerPaths';
import { Dispatch } from 'redux';
import { UtilTypes } from '../../types/utilTypes';
import { Redux } from '../../types/store/redux';
import Utils from '../../utils/utils';

const entity = 'App';

export const AppActionTypes = {
  SAVE_USER_INFO: `${entity}.SAVE_USER_INFO`,
  SAVE_CURRENT_LICENSE_TYPE: `${entity}.SAVE_CURRENT_LICENSE_TYPE`,
  SAVE_LOCALIZATION: `${entity}.SAVE_LOCALIZATION`,
  CLEAR_APP_DATA: `${entity}.CLEAR_APP_DATA`,
};

export default class AppActions {
  static getToken = (authCredentials: DataTypes.AuthCredentials) => () => {
    return new Promise((resolve, reject) => {
      Api.getToken(authCredentials).then(
        (response: AxiosResponse<DataTypes.Token>) => {
          LocalStorage.token.set(response.data.access);
          resolve();
        },
        (reason) => {
          console.error(reason);
          reject();
        }
      );
    });
  };

  static getUserInfo = () => (dispatch: Dispatch) => {
    return new Promise((resolve: (routeData: UtilTypes.LoginRouteData) => void) => {
      Api.getUserInfo().then((response: AxiosResponse<DataTypes.UserData>) => {
        dispatch({
          type: AppActionTypes.SAVE_USER_INFO,
          payload: response.data,
        });

        if (response.data.is_superuser) {
          resolve({ path: RouterPaths.profilePage });
        } else if (response.data.trainer?.competition) {
          resolve({
            path: RouterPaths.competitionPage,
            params: { competitionId: response.data.trainer.competition },
          });
        }
      });
    });
  };

  static getLocale = () => (dispatch: Redux.ThunkDispatch) => {
    Api.getLocale().then((response: AxiosResponse<{ locale: DataTypes.Locale[] }>) => {
      if (response.data.locale.length === 0) return;

      const lang = Utils.getNavigatorLanguage().slice(0, 2);

      const locale = response.data.locale.find((locale) =>
        locale.language_code.toLowerCase().includes(lang)
      );

      let fileLocation;
      if (locale == null) {
        fileLocation = response.data.locale[0].content_file;
      } else {
        fileLocation = locale.content_file;
      }

      dispatch(AppActions.getLocaleFile(fileLocation));
    });
  };

  static getLocaleFile = (fileLocation: string) => (dispatch: Dispatch) => {
    Api.getLocaleFile(fileLocation).then((response: AxiosResponse<Record<string, string>>) => {
      dispatch({
        type: AppActionTypes.SAVE_LOCALIZATION,
        payload: response.data,
      });
    });
  };

  static saveCurrentLicenseType = (licenseType: number | null) => ({
    type: AppActionTypes.SAVE_CURRENT_LICENSE_TYPE,
    payload: licenseType,
  });

  static clearAppData = () => ({
    type: AppActionTypes.CLEAR_APP_DATA,
  });
}
