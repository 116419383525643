import Numbers from '../constants/numbers';

export default class FileUtils {
  static downloadFile(
    blobPart: BlobPart,
    options: BlobPropertyBag,
    qualifiedName: string,
    outputFileName: string
  ) {
    const blob = new Blob([blobPart], options);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(qualifiedName, outputFileName);
    link.click();
  }

  static getFilesFormData(fileList: File[]) {
    const files = new FormData();
    fileList.forEach((file) => files.append('file', file));

    return files;
  }

  static getFileMeta(fileName: string) {
    const extension = fileName.split('.')[fileName.split('.').length - 1];
    return {
      extension: (extension === 'docx' && 'doc') || extension,
      fileName: decodeURI(fileName.split('/')[fileName.split('/').length - 1].split('.')[0]),
    };
  }

  static getFilteredFilesByAllowedExtensions(
    fileList: FileList | null,
    allowedExtensions: string[]
  ): File[] {
    if (fileList == null) return [];

    return Array.from(fileList).filter((file) => {
      const fileExtension = this.getFileMeta(file.name).extension.toLowerCase();
      const isValidExtension = allowedExtensions.includes(fileExtension);
      const isValidSize = file.size <= Numbers.MAX_FILE_SIZE;

      return isValidExtension && isValidSize;
    });
  }

  static hasRestrictedExtension(file: File | null, allowedExtensions: string[]) {
    if (file == null) return false;

    const fileExtension = this.getFileMeta(file.name).extension.toLowerCase();

    return !allowedExtensions.includes(fileExtension);
  }

  static isAnyFileHasRestrictedExtension(fileList: FileList | null, allowedExtensions: string[]) {
    if (fileList == null) return false;

    return Array.from(fileList).some((file) =>
      this.hasRestrictedExtension(file, allowedExtensions)
    );
  }

  static isOversized(file: File | null) {
    if (file == null) return false;

    return file.size > Numbers.MAX_FILE_SIZE;
  }

  static isAnyFileOversized(fileList: FileList | null) {
    if (fileList == null) return false;

    return Array.from(fileList).some(this.isOversized);
  }

  /**
   * Returns array of renamed files if the names of these files have already been in existingFiles array.
   * Example:
   *
   * fileList: [ File( name: 'example.doc', ... ) ]
   * existingFiles: [ File( name: 'example.doc', ... ) ]
   *
   * result: [ File( name: 'example (1).doc', ... ) ]
   *
   * @param fileList
   * @param existingFiles
   */
  static getRenamedFilesIfDuplicated(fileList: File[] | null, existingFiles: File[]) {
    if (fileList == null) return [];

    return fileList.map((file) => {
      if (existingFiles.some((existingFile) => existingFile.name === file.name)) {
        const fileMeta = this.getFileMeta(file.name);

        const copyNumber = existingFiles.reduce((accumulator, existingFile) => {
          if (existingFile.name === `${fileMeta.fileName} (${accumulator}).${fileMeta.extension}`) {
            return accumulator + 1;
          }
          return accumulator;
        }, 1);

        const blob = file.slice(0, file.size, file.type);

        return new File([blob], `${fileMeta.fileName} (${copyNumber}).${fileMeta.extension}`, {
          type: file.type,
        });
      }

      return file;
    });
  }
}
