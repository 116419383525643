import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import StoreUtils from '../../utils/storeUtils';

export const CategoriesActionTypes = StoreUtils.getGenericActionTypes('Categories');

export default class CategoriesActions {
  static getCategories = () => (dispatch: Dispatch) => {
    dispatch({ type: CategoriesActionTypes.FETCH_PENDING });

    Api.getCategories().then(
      (result: AxiosResponse<DataTypes.Category[]>) => {
        dispatch({
          type: CategoriesActionTypes.FETCH_SUCCESS,
          payload: result.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: CategoriesActionTypes.FETCH_FAILURE });
      }
    );
  };
}
