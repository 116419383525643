import FetchConstants from '../../constants/fetchConstants';
import { CompetitionTypes } from '../../types/store/competitionTypes';
import { CompetitionTypesActionTypes } from '../actions/competitionTypesActions';

const initialState: CompetitionTypes.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function competitionTypesReducer(
  state = initialState,
  action: CompetitionTypes.ActionTypes
) {
  switch (action.type) {
    case CompetitionTypesActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case CompetitionTypesActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case CompetitionTypesActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
