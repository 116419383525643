import './Breadcrumbs.scss';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MaterialBreadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import BreadcrumbsHooks from './BreadcrumbsHooks';

export interface BreadPath {
  text: string;
  link: string;
}

export default function Breadcrumbs() {
  const pathBread = BreadcrumbsHooks.usePathBread();

  function renderClickableLink(value: BreadPath) {
    return (
      <Link
        className="breadcrumbs__item breadcrumbs__item--link"
        key={value.link}
        component={RouterLink}
        to={value.link}
      >
        {value.text}
      </Link>
    );
  }

  function renderStaticText(value: BreadPath) {
    return (
      <Typography key={value.link} className="breadcrumbs__item">
        {value.text}
      </Typography>
    );
  }

  return (
    <MaterialBreadcrumbs
      maxItems={4}
      separator={<NavigateNextIcon fontSize="small" />}
      className="breadcrumbs"
    >
      {pathBread.map((value, index) => {
        const isLast = index === pathBread.length - 1;
        return value.link && !isLast ? renderClickableLink(value) : renderStaticText(value);
      })}
    </MaterialBreadcrumbs>
  );
}
