import FetchConstants from '../../constants/fetchConstants';
import { Genders } from '../../types/store/genders';
import { GendersActionTypes } from '../actions/gendersActions';

const initialState: Genders.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function gendersReducer(state = initialState, action: Genders.ActionTypes) {
  switch (action.type) {
    case GendersActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case GendersActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case GendersActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
