import FetchConstants from '../../constants/fetchConstants';
import { Competitions } from '../../types/store/competitions';
import { CompetitionsActionTypes } from '../actions/competitionsActions';

const initialState: Competitions.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function competitionsReducer(
  state = initialState,
  action: Competitions.ActionTypes
) {
  switch (action.type) {
    case CompetitionsActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case CompetitionsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case CompetitionsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
