import { App } from '../../types/store/app';
import { AppActionTypes } from '../actions/appActions';
import { DataTypes } from '../../types/dataTypes';

const initialState: App.State = {
  userData: {},
  currentLicenseType: null,
  localization: {},
};

export default function appReducer(state = initialState, action: App.ActionTypes) {
  switch (action.type) {
    case AppActionTypes.SAVE_USER_INFO:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...(action.payload as DataTypes.UserData),
        },
      };
    case AppActionTypes.SAVE_CURRENT_LICENSE_TYPE:
      return {
        ...state,
        currentLicenseType: action.payload,
      };
    case AppActionTypes.SAVE_LOCALIZATION:
      return {
        ...state,
        localization: action.payload as Record<string, string>,
      };
    case AppActionTypes.CLEAR_APP_DATA:
      return {
        ...initialState,
        localization: state.localization,
      };
    default:
      return state;
  }
}
