import { Redux } from '../../types/store/redux';

export default class CompetitionSelectors {
  static data = (state: Redux.RootState) => state.competition.data;

  static fetchStatus = (state: Redux.RootState) => state.competition.fetchStatus;

  static competitionName = (state: Redux.RootState) => state.competition.data.name;

  static competitionType = (state: Redux.RootState) => state.competition.data.typeOfComp;

  static competitionId = (state: Redux.RootState) => state.competition.data.id;

  static competitionStartDate = (state: Redux.RootState) => state.competition.data.dateStart;

  static competitionEndDate = (state: Redux.RootState) => state.competition.data.dateEnd;

  static competitionCloseRegDate = (state: Redux.RootState) => state.competition.data.dateCloseReg;

  static files = (state: Redux.RootState) => state.competition.files;
}
