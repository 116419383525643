import { lazy } from 'react';
import { RouteService } from '../types/services/routeService';
import MultiRouteSwitcher from '../components/multiRouteSwitcher';
import RouterSettings from '../constants/routerSettings';

const IndexPage = lazy(() => import('../pages/indexPage'));
const ProfilePage = lazy(() => import('../pages/profilePage'));
const LicensePage = lazy(() => import('../pages/licensePage'));
const CompetitionPage = lazy(() => import('../pages/competitionPage'));
const AthletePage = lazy(() => import('../pages/athletePage'));
const DistancePage = lazy(() => import('../pages/distancePage'));
const FreeCompetitionPage = lazy(() => import('../pages/freeCompetitionPage'));
const Page404 = lazy(() => import('../pages/page404'));

const Routes: RouteService.Route[] = [
  {
    path: '/',
    component: IndexPage,
    settings: {
      exact: true,
      isPrivate: false,
    },
    componentSettings: {
      useSuspense: true,
      usePageWrapper: false,
    },
  },
  {
    path: '/profile',
    component: MultiRouteSwitcher,
    settings: {
      exact: false,
      isPrivate: true,
    },
    componentSettings: {
      useSuspense: true,
      usePageWrapper: false,
    },
    subRoutes: [
      {
        path: '/',
        component: ProfilePage,
        settings: {
          exact: true,
          isPrivate: true,
        },
        componentSettings: RouterSettings.DefaultComponentSettings,
      },
      {
        path: '/license',
        component: LicensePage,
        settings: {
          exact: true,
          isPrivate: true,
        },
        componentSettings: RouterSettings.DefaultComponentSettings,
      },
      {
        path: '/competition/:competitionId',
        component: MultiRouteSwitcher,
        settings: {
          exact: false,
          isPrivate: true,
        },
        componentSettings: {
          useSuspense: true,
          usePageWrapper: false,
        },
        subRoutes: [
          {
            path: '/',
            component: CompetitionPage,
            settings: {
              exact: true,
              isPrivate: true,
            },
            componentSettings: {
              useSuspense: true,
              usePageWrapper: false,
            },
          },
          {
            path: '/distance/:distanceId',
            component: MultiRouteSwitcher,
            settings: {
              exact: false,
              isPrivate: true,
            },
            componentSettings: {
              useSuspense: true,
              usePageWrapper: false,
            },
            subRoutes: [
              {
                path: '/',
                component: DistancePage,
                settings: {
                  exact: true,
                  isPrivate: true,
                },
                componentSettings: RouterSettings.DefaultComponentSettings,
              },
              {
                path: '/athlete/:athleteId',
                component: AthletePage,
                settings: {
                  exact: false,
                  isPrivate: true,
                },
                componentSettings: RouterSettings.DefaultComponentSettings,
              },
            ],
          },
          {
            path: '/athlete/:athleteId',
            component: AthletePage,
            settings: {
              exact: true,
              isPrivate: true,
            },
            componentSettings: RouterSettings.DefaultComponentSettings,
          },
        ],
      },
    ],
  },
  {
    path: '/competitions/:competitionId',
    component: FreeCompetitionPage,
    settings: {
      exact: true,
      isPrivate: false,
    },
    componentSettings: RouterSettings.DefaultComponentSettings,
  },
  {
    path: '*',
    component: Page404,
    settings: {
      exact: false,
      isPrivate: false,
    },
    componentSettings: {
      useSuspense: true,
      usePageWrapper: false,
    },
  },
];

export default Routes;
