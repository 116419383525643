import { DataTypes } from '../types/dataTypes';
import DateUtils from './dateUtils';

export default class Utils {
  static formatSessionToTitle(event: DataTypes.SessionEvent) {
    if (event?.session?.sessionTime == null) return '';

    let sessionTime: Date | string = event.session.sessionTime;
    sessionTime = DateUtils.getCurrentTimeByTimezone(sessionTime);

    return sessionTime.toLocaleString(this.getNavigatorLanguage(), {
      weekday: 'short',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  static handleRequestSort(
    newOrderBy: string,
    order: string,
    orderBy: string
  ): ['desc' | 'asc', string] {
    let newOrder: 'desc' | 'asc' = 'desc';
    if (orderBy === newOrderBy && order === 'desc') {
      newOrder = 'asc';
    }
    return [newOrder, newOrderBy];
  }

  static numberComparator<T extends { number: number }>(a: T, b: T) {
    if (a.number > b.number) {
      return 1;
    }
    if (a.number < b.number) {
      return -1;
    }
    return 0;
  }

  static getAthleteFullName<T extends { firstName: string; lastName: string }>(
    athlete: T,
    lastNameFirst = false
  ) {
    if (lastNameFirst) {
      return `${athlete.lastName} ${athlete.firstName}`;
    }

    return `${athlete.firstName} ${athlete.lastName}`;
  }

  static isObject(value: any): boolean {
    if (value === null) return false;

    return typeof value !== 'function' && typeof value === 'object';
  }

  static getPurifiedObject<
    T extends Record<string, any>,
    Q extends Record<string, any> & Partial<T>
  >(initialObject: T, alteredObject: Q): Partial<Q> {
    return Object.keys(initialObject).reduce((accumulator, key) => {
      if (!alteredObject.hasOwnProperty(key)) return accumulator;

      if (this.isObject(alteredObject[key])) {
        return {
          ...accumulator,
          [key]: this.getPurifiedObject(initialObject[key], alteredObject[key]),
        };
      }

      if (initialObject[key] !== alteredObject[key]) {
        return { ...accumulator, [key]: alteredObject[key] };
      }

      return accumulator;
    }, {});
  }

  static getNavigatorLanguage(fallbackLanguage = 'ru-RU') {
    if (navigator.language != null && navigator.language !== '') {
      return navigator.language;
    }

    if (navigator?.languages.length !== 0) {
      return navigator.languages[0];
    }

    return fallbackLanguage;
  }

  static getFilteredCompetitions(competitions: DataTypes.Competition[]) {
    const currentTime = new Date();

    const upcoming = competitions.filter((element) => {
      const dateEnd = DateUtils.getRoundedDate(element.dateEnd);

      return dateEnd > currentTime;
    });

    const finished = competitions.filter((element) => {
      const dateEnd = DateUtils.getRoundedDate(element.dateEnd);

      return dateEnd < currentTime;
    });

    return [upcoming, finished];
  }
}
