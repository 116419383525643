export const GendersID = {
  MALE: 1,
  FEMALE: 2,
  NO: 3,
};

export const GendersChar = {
  MALE: 'M',
  FEMALE: 'Ж',
  NO: ' ',
};
