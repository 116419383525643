import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import StoreUtils from '../../utils/storeUtils';

export const GendersActionTypes = StoreUtils.getGenericActionTypes('Genders');

export default class GendersActions {
  static getGenders = () => (dispatch: Dispatch) => {
    dispatch({ type: GendersActionTypes.FETCH_PENDING });

    Api.getGenders().then(
      (result: AxiosResponse<DataTypes.Gender[]>) => {
        dispatch({
          type: GendersActionTypes.FETCH_SUCCESS,
          payload: result.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: GendersActionTypes.FETCH_FAILURE });
      }
    );
  };
}
