import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import { Redux } from '../../types/store/redux';
import AppSelectors from '../selectors/appSelectors';
import AppActions from './appActions';
import StoreUtils from '../../utils/storeUtils';

export const CompetitionTypesActionTypes = StoreUtils.getGenericActionTypes('CompetitionTypes');

export default class CompetitionTypesActions {
  static getCompetitionTypes = () => (dispatch: Dispatch, getState: Redux.ThunkGetState) => {
    dispatch({ type: CompetitionTypesActionTypes.FETCH_PENDING });

    Api.getCompetitionTypes().then(
      (result: AxiosResponse<DataTypes.CompetitionType[]>) => {
        dispatch({
          type: CompetitionTypesActionTypes.FETCH_SUCCESS,
          payload: result.data,
        });

        // TODO: replace from here?:

        const state = getState();
        const currentLicenseType = AppSelectors.currentLicenseType(state);

        let licenseType = null;

        if (currentLicenseType == null && result.data.length !== 0) {
          licenseType = result.data[0].id;
        } else if (currentLicenseType != null) {
          licenseType = currentLicenseType;
        }

        dispatch(AppActions.saveCurrentLicenseType(licenseType));
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: CompetitionTypesActionTypes.FETCH_FAILURE });
      }
    );
  };
}
