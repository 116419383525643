import { Redux } from '../types/store/redux';

export default class StoreUtils {
  static getGenericActionTypes(entityName: string): Redux.GenericActionTypes {
    return {
      FETCH_PENDING: `${entityName}.FETCH_PENDING`,
      FETCH_SUCCESS: `${entityName}.FETCH_SUCCESS`,
      FETCH_FAILURE: `${entityName}.FETCH_FAILURE`,
    };
  }
}
