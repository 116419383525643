import React from 'react';
import Utils from './utils';
import FileUtils from './fileUtils';
import { useSelector } from 'react-redux';
import AppSelectors from '../store/selectors/appSelectors';
import LocaleUtils from './localeUtils';
import { DataTypes } from '../types/dataTypes';
import { GendersID } from '../constants/genderConstants';
import { UtilTypes } from '../types/utilTypes';
import { Reducer } from '../types/reducer';

interface SortRequestState {
  order: 'desc' | 'asc';
  orderBy: string;
}

type SortRequestAction = {
  type: 'SET_ORDER_FIELDS';
  payload: { order: 'desc' | 'asc'; orderBy: string };
};

function sortRequestReducer(state: SortRequestState, action: SortRequestAction): SortRequestState {
  switch (action.type) {
    case 'SET_ORDER_FIELDS':
      return {
        ...state,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
      };
    default:
      throw new Error();
  }
}

export default class CustomHooks {
  static useLocalization() {
    const localization = useSelector(AppSelectors.localization);

    return React.useCallback(
      (locKey: string, params: Record<string, any> = {}) => {
        const locItem = localization[locKey];

        return LocaleUtils.getProcessedLocItem(locItem, params);
      },
      [localization]
    );
  }

  static usePageTitle(title: string) {
    React.useEffect(() => {
      document.title = `${title} - ФПСТО`; // TODO: l10n
    }, [title]);
  }

  static useSortRequest(
    initialOrder: 'desc' | 'asc',
    initialOrderBy: string
  ): ['desc' | 'asc', string, (property: string) => void] {
    const [state, dispatch] = React.useReducer(sortRequestReducer, {
      order: initialOrder,
      orderBy: initialOrderBy,
    });

    const setProperty = React.useCallback(
      (value: string) => {
        const [order, orderBy] = Utils.handleRequestSort(value, state.order, state.orderBy);

        dispatch({ type: 'SET_ORDER_FIELDS', payload: { order, orderBy } });
      },
      [state.order, state.orderBy]
    );

    return [state.order, state.orderBy, setProperty];
  }

  static useFileMeta(filename: string): [string, string] {
    const { fileName, extension: fileExtension } = FileUtils.getFileMeta(filename);

    return [fileName, fileExtension];
  }

  static useStateWithReset<T = {}>(
    initialValues: T
  ): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
    const [data, setData] = React.useState(initialValues);

    const resetForm = () => {
      setData(initialValues);
    };

    return [data, setData, resetForm];
  }

  static useEventTitleFormat<
    T extends {
      nomination: DataTypes.Nomination;
      category: DataTypes.Category;
      gender: DataTypes.Gender;
    }
  >(): (data?: T) => string {
    const l = this.useLocalization();

    return React.useCallback(
      (data): string => {
        if (data == null || Object.keys(data).length === 0) return '';

        const {
          nomination: { nominationName },
          category: { category },
          gender: { id: genderId },
        } = data;

        let gender;

        switch (genderId) {
          case GendersID.NO:
            gender = l('event/title/gender/both');
            break;
          case GendersID.FEMALE:
            gender = l('event/title/gender/women');
            break;
          case GendersID.MALE:
            gender = l('event/title/gender/men');
            break;
          default:
            gender = '';
        }

        return `${nominationName}, ${gender} (${category})`;
      },
      [l]
    );
  }

  static useCompareObjects<T extends UtilTypes.ComparableObject>(): (
    a: T,
    b: T,
    orderBy: keyof T | string
  ) => number {
    const formatEventTitle = this.useEventTitleFormat();

    return React.useCallback(
      (a, b, orderBy) => {
        let comparedObject1;
        let comparedObject2;

        switch (orderBy) {
          case 'subject': {
            comparedObject1 = a[orderBy].name;
            comparedObject2 = b[orderBy].name;
            break;
          }
          case 'rank': {
            comparedObject1 = a[orderBy].id;
            comparedObject2 = b[orderBy].id;
            break;
          }
          case 'fullName': {
            if (
              a.lastName == null ||
              a.firstName == null ||
              b.lastName == null ||
              b.firstName == null
            ) {
              break;
            }

            comparedObject1 = a.lastName + a.firstName;
            comparedObject2 = b.lastName + b.firstName;
            break;
          }
          case 'distance': {
            comparedObject1 = formatEventTitle(a.event);
            comparedObject2 = formatEventTitle(b.event);
            break;
          }
          default: {
            comparedObject1 = a[orderBy];
            comparedObject2 = b[orderBy];
            break;
          }
        }
        if (typeof comparedObject1 === 'string' && typeof comparedObject2 === 'string')
          return comparedObject1.localeCompare(comparedObject2);

        if (comparedObject1 < comparedObject2) {
          return -1;
        }

        if (comparedObject1 > comparedObject2) {
          return 1;
        }

        return 0;
      },
      [formatEventTitle]
    );
  }

  static useSortArray(): (array: any[], order: 'asc' | 'desc', orderBy: string) => any[] {
    const compareObjects = this.useCompareObjects();

    return React.useCallback(
      (array, order, orderBy) => {
        const formattedArray = array.map((el, index) => [el, index]);
        formattedArray.sort((a, b) => {
          const newOrder =
            order === 'desc'
              ? compareObjects(a[0], b[0], orderBy)
              : -compareObjects(a[0], b[0], orderBy);
          return newOrder !== 0 ? newOrder : a[1] - b[1];
        });
        return formattedArray.map((el) => el[0]);
      },
      [compareObjects]
    );
  }

  static useReducer<Data>(
    reducer: Reducer.Reducer<Data>,
    initialState: Reducer.CommonDataState<Data>
  ): [
    React.ReducerState<Reducer.Reducer<Data>>,
    React.Dispatch<React.ReducerAction<Reducer.Reducer<Data>>>
  ] {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return [state, dispatch];
  }
}
