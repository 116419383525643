import React, { Suspense } from 'react';
import { RouteService } from '../../types/services/routeService';
import PageWrapper from '../pageWrapper';
import PageLoader from '../pageLoader';

interface Props extends RouteService.RouteComponentProps {
  component: RouteService.RouteComponent;
  componentSettings: RouteService.ComponentSettings;
}

export default function LayoutBuilder(props: Props) {
  const { component: Component, componentSettings } = props;

  // TODO: extend Suspense to be available to add in the routeConfig

  if (componentSettings.useSuspense) {
    if (componentSettings.usePageWrapper) {
      return (
        <PageWrapper>
          <Suspense fallback={<PageLoader />}>
            <Component subRoutes={props.subRoutes} />
          </Suspense>
        </PageWrapper>
      );
    }

    return (
      <Suspense fallback={<PageLoader />}>
        <Component subRoutes={props.subRoutes} />
      </Suspense>
    );
  }

  if (componentSettings.usePageWrapper) {
    return (
      <PageWrapper>
        <Component subRoutes={props.subRoutes} />
      </PageWrapper>
    );
  }

  return <Component />;
}
