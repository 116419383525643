import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import StoreUtils from '../../utils/storeUtils';
import FileUtils from '../../utils/fileUtils';

export const EventActionTypes = {
  ...StoreUtils.getGenericActionTypes('Event'),
  CLEAR_DATA: 'Event.CLEAR_DATA',
};

export default class EventActions {
  static getEvent = (distanceId: DataTypes.Id) => (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: EventActionTypes.FETCH_PENDING });

      Api.getEvent(distanceId).then(
        (result: AxiosResponse<DataTypes.SessionEvent>) => {
          dispatch({
            type: EventActionTypes.FETCH_SUCCESS,
            payload: result.data,
          });
          resolve();
        },
        (reason) => {
          console.error(reason);
          dispatch({ type: EventActionTypes.FETCH_FAILURE });
          reject();
        }
      );
    });
  };

  static clearEvent = () => ({
    type: EventActionTypes.CLEAR_DATA,
  });

  static getExcelByEvent = (order: string, orderBy: string, eventId: DataTypes.Id) => () => {
    return new Promise((resolve, reject) => {
      const fields = {
        time: 'time',
        withoutScore: 'withoutScore',
        fullName: 'fullName',
        sportsman: 'sportsman__',
        lastName: 'lastName',
        desc: 'desc',
        sort: 'sort',
      };

      const params = new URLSearchParams();
      let sortValue: string;
      switch (orderBy) {
        case fields.time:
        case fields.withoutScore:
          sortValue = orderBy;
          break;
        case fields.fullName:
          sortValue = `${fields.sportsman}${fields.lastName}`;
          break;
        default:
          sortValue = `${fields.sportsman}${orderBy}`;
          break;
      }
      params.append(fields.sort, order === fields.desc ? sortValue : `-${sortValue}`);

      Api.exportByEvent(eventId, params).then(
        (result: AxiosResponse<BlobPart>) => {
          FileUtils.downloadFile(
            result.data,
            { type: 'application/vnd.ms-excel' },
            'download',
            'Technical_application.xls'
          );
          resolve();
        },
        () => reject()
      );
    });
  };

  static getEventPlaces = (eventId: DataTypes.Id) => () => {
    return new Promise((resolve: (data: { placeInRace: any }[]) => void, reject) => {
      Api.getEventPlacesByTrainer(eventId).then(
        (response: AxiosResponse<{ placeInRace: any }[]>) => {
          resolve(response.data);
        },
        () => reject()
      );
    });
  };

  static postEvent = (eventData: DataTypes.Event) => () => {
    return new Promise((resolve, reject) => {
      Api.postEvent(eventData).then(
        (response) => {
          if (response.status === 201) {
            resolve();
          }
        },
        () => reject()
      );
    });
  };

  static deleteEvent = (eventId: DataTypes.Id) => () => {
    return new Promise((resolve, reject) => {
      Api.deleteEvent(eventId).then(
        () => resolve(),
        () => reject()
      );
    });
  };
}
