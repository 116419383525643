import React from 'react';
import LocaleUtils from '../../utils/localeUtils';
import { useSelector } from 'react-redux';
import AppSelectors from '../../store/selectors/appSelectors';

export default function LocItem(props: { locKey: string; params?: Record<string, any> }) {
  const localization = useSelector(AppSelectors.localization);

  return <>{LocaleUtils.getProcessedLocItem(localization[props.locKey], props.params)}</>;
}
