import React from 'react';
import { Route as ReactRouterRoute, Switch } from 'react-router-dom';
import PrivateRoute from '../privateRoute';
import { RouteService } from '../../types/services/routeService';
import LayoutBuilder from '../layoutBuilder';
import AppHooks from './AppHooks';

interface Props {
  routes: RouteService.Route[];
}

export default function App(props: Props) {
  AppHooks.useLocale();
  AppHooks.useConsoleReport();

  return (
    <Switch>
      {props.routes.map((route) => {
        const Route = route.settings.isPrivate ? PrivateRoute : ReactRouterRoute;
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.settings.exact}
            render={() => (
              <LayoutBuilder
                component={route.component}
                subRoutes={route.subRoutes}
                componentSettings={route.componentSettings}
              />
            )}
          />
        );
      })}
    </Switch>
  );
}
