import Utils from './utils';

export default class DateUtils {
  static eventDateIsAvailable(eventDateRaw: string | number | Date) {
    const currentDate = new Date();
    const eventDate = this.getRoundedDate(eventDateRaw);

    return currentDate < eventDate;
  }

  /**
   * Returns date rounded to next day
   * @param date
   */
  static getRoundedDate(date: string | number | Date) {
    const roundedDate = new Date(date);
    roundedDate.setDate(roundedDate.getDate() + 1);

    return roundedDate;
  }

  static getCurrentTimeByTimezone(date: string | number | Date) {
    let innerDate = new Date(date);

    const offsetDate = innerDate.getTime() + innerDate.getTimezoneOffset() * 60000;

    return new Date(offsetDate);
  }

  static getCurrentYear() {
    return new Date().getFullYear();
  }

  static isValidDate(date: string | number | Date): boolean {
    if (typeof date === 'string') {
      if (date === '' || isNaN(Date.parse(date))) return false;
    }

    return true;
  }

  static getFormattedDate(date1: string | number | Date, date2?: string | number | Date) {
    if (!this.isValidDate(date1) || (date2 != null && !this.isValidDate(date2))) return '';

    const language = Utils.getNavigatorLanguage();

    const start = new Date(date1);

    if (date2 == null) {
      return start.toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric' });
    }

    const end = new Date(date2);

    let startString;
    let endString;
    let isTheSameDate = false;

    if (start.getFullYear() !== end.getFullYear()) {
      startString = start.toLocaleDateString(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      endString = end.toLocaleDateString(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    } else if (start.getMonth() !== end.getMonth()) {
      startString = start.toLocaleDateString(language, { month: 'long', day: 'numeric' });
      endString = end.toLocaleDateString(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    } else if (start.getDate() !== end.getDate()) {
      startString = start.toLocaleDateString(language, { month: 'long', day: 'numeric' });
      endString = end.toLocaleDateString(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    } else {
      isTheSameDate = true;
    }

    if (!isTheSameDate) {
      return `${startString} - ${endString}`;
    }

    return start.toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric' });
  }

  static getFormattedTime(time: string) {
    const newTime = time.split(/[,.]/);
    if (newTime.length < 3) return newTime[0] || '0';
    return `${newTime[0].padStart(2, '0')}:${newTime[1].padStart(2, '0')}:${newTime[2]}`;
  }

  static getDateForRequest(dateInput: string | number | Date | null) {
    if (dateInput == null) return '';

    const date = new Date(dateInput);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedMonth = month.toString().length > 1 ? month : `0${month}`;
    const formattedDay = day.toString().length > 1 ? day : `0${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  static getDateTimeForRequest(dateInput: string | number | Date) {
    const date = new Date(dateInput);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    const formattedMonth = month.toString().length > 1 ? month : `0${month}`;
    const formattedDay = day.toString().length > 1 ? day : `0${day}`;
    const formattedHour = hour.toString().length > 1 ? hour : `0${hour}`;
    const formattedMinute = minute.toString().length > 1 ? minute : `0${minute}`;

    return `${year}-${formattedMonth}-${formattedDay} ${formattedHour}:${formattedMinute}:00`;
  }

  static formatSession(rawDate: string | number | Date) {
    const fullDate = new Date(rawDate);

    return fullDate.toLocaleString('RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });
  }

  static dateWithoutTimeStamp(date?: number | string | Date) {
    const now = new Date();
    return date
      ? new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate())
      : new Date(now.getFullYear(), now.getMonth(), now.getDate());

    // Won't work because of DatePicker used in CompetitionModal:

    // if (date == null || isNaN(new Date(date).getTime())) {
    //   return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // }
    //
    // const dateObject = new Date(date);
    //
    // return new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());
  }

  static dateToUnixTimeStamp(date: string | Date) {
    return Date.parse(date.toString()) / 1000;
  }
}
