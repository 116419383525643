import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import StoreUtils from '../../utils/storeUtils';

export const EventsActionTypes = StoreUtils.getGenericActionTypes('Events');

export default class EventsActions {
  static getEvents = (competitionId: string) => (dispatch: Dispatch) => {
    dispatch({ type: EventsActionTypes.FETCH_PENDING });

    Api.getEvents(competitionId).then(
      (result: AxiosResponse<DataTypes.EventVerbose[]>) => {
        dispatch({
          type: EventsActionTypes.FETCH_SUCCESS,
          payload: result.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: EventsActionTypes.FETCH_FAILURE });
      }
    );
  };

  static patchEvents = (events: DataTypes.EventPatch[]) => () => {
    return new Promise((resolve, reject) => {
      Api.patchEvents(events).then(
        () => resolve(),
        () => reject()
      );
    });
  };
}
