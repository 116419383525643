import FetchConstants from '../../constants/fetchConstants';
import { Session } from '../../types/store/session';
import { SessionActionTypes } from '../actions/sessionActions';

const initialState: Session.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function sessionReducer(state = initialState, action: Session.ActionTypes) {
  switch (action.type) {
    case SessionActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case SessionActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case SessionActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
