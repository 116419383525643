import './PageWrapper.scss';
import React from 'react';
import Breadcrumbs from '../breadcrumbs';

interface Props {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
}

export default function PageWrapper(props: Props) {
  return (
    <div className="page-wrapper">
      <header className="page-wrapper__header">
        <Breadcrumbs />
        {props.headerContent}
      </header>
      {props.children}
    </div>
  );
}
