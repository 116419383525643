import React from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Route as ReactRouterRoute } from 'react-router';
import { RouteService } from '../../types/services/routeService';
import PrivateRoute from '../privateRoute';
import LayoutBuilder from '../layoutBuilder';

export default function MultiRouteSwitcher(props: RouteService.RouteComponentProps) {
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <Switch location={location}>
      {props.subRoutes != null &&
        props.subRoutes.map((route) => {
          const Route = route.settings.isPrivate ? PrivateRoute : ReactRouterRoute;
          return (
            <Route
              key={route.path}
              path={`${match.path}${route.path}`}
              exact={route.settings.exact}
              render={() => (
                <LayoutBuilder
                  component={route.component}
                  subRoutes={route.subRoutes}
                  componentSettings={route.componentSettings}
                />
              )}
            />
          );
        })}
    </Switch>
  );
}
