import FetchConstants from '../../constants/fetchConstants';
import { AthletesTypes } from '../../types/store/athletes';
import { AthletesActionTypes } from '../actions/athletesActions';

const initialState: AthletesTypes.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
  dataByEvent: [],
  fetchStatusByEvent: FetchConstants.STALLING,
};

export default function athletesReducer(state = initialState, action: AthletesTypes.ActionTypes) {
  switch (action.type) {
    case AthletesActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case AthletesActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload as AthletesTypes.Data,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case AthletesActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    case AthletesActionTypes.FETCH_BY_EVENT_PENDING:
      return {
        ...state,
        fetchStatusByEvent: FetchConstants.PENDING,
      };
    case AthletesActionTypes.FETCH_BY_EVENT_SUCCESS:
      return {
        ...state,
        dataByEvent: action.payload,
        fetchStatusByEvent: FetchConstants.SUCCESS,
      };
    case AthletesActionTypes.FETCH_BY_EVENT_FAILURE:
      return {
        ...state,
        fetchStatusByEvent: FetchConstants.FAILURE,
      };
    case AthletesActionTypes.CLEAR_ATHLETES_BY_EVENT:
      return {
        ...state,
        dataByEvent: initialState.dataByEvent,
        fetchStatusByEvent: initialState.fetchStatusByEvent,
      };
    default:
      return state;
  }
}
