import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import StoreUtils from '../../utils/storeUtils';

export const AthletesActionTypes = {
  ...StoreUtils.getGenericActionTypes('Athletes'),
  FETCH_BY_EVENT_PENDING: 'Athletes.FETCH_BY_EVENT_PENDING',
  FETCH_BY_EVENT_SUCCESS: 'Athletes.FETCH_BY_EVENT_SUCCESS',
  FETCH_BY_EVENT_FAILURE: 'Athletes.FETCH_BY_EVENT_FAILURE',
  CLEAR_ATHLETES_BY_EVENT: 'Athletes.CLEAR_ATHLETES_BY_EVENT',
};

export default class AthletesActions {
  static getAthletes = () => (dispatch: Dispatch) => {
    dispatch({ type: AthletesActionTypes.FETCH_PENDING });

    Api.getAthletes().then(
      (result: AxiosResponse<DataTypes.ActiveLicense[]>) => {
        dispatch({
          type: AthletesActionTypes.FETCH_SUCCESS,
          payload: result.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: AthletesActionTypes.FETCH_FAILURE });
      }
    );
  };

  static getAthletesByEvent = (eventId: DataTypes.Id) => (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: AthletesActionTypes.FETCH_BY_EVENT_PENDING });

      Api.getAthletesByEvent(eventId).then(
        (result: AxiosResponse<DataTypes.AthleteByEvent[]>) => {
          dispatch({
            type: AthletesActionTypes.FETCH_BY_EVENT_SUCCESS,
            payload: result.data,
          });
          resolve();
        },
        (reason) => {
          console.error(reason);
          dispatch({ type: AthletesActionTypes.FETCH_BY_EVENT_FAILURE });
          reject();
        }
      );
    });
  };

  static clearAthletesByEvent = () => ({
    type: AthletesActionTypes.CLEAR_ATHLETES_BY_EVENT,
  });
}
