import FetchConstants from '../../constants/fetchConstants';
import { Categories } from '../../types/store/categories';
import { CategoriesActionTypes } from '../actions/categoriesActions';

const initialState: Categories.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function categoriesReducer(state = initialState, action: Categories.ActionTypes) {
  switch (action.type) {
    case CategoriesActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case CategoriesActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case CategoriesActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
