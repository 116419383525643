import { LocalStorageItem } from './classes';

class LocalStorage {
  static token = new LocalStorageItem('token');

  // Redux store state
  static state = new LocalStorageItem('state');
}

export default LocalStorage;
