import FetchConstants from '../../constants/fetchConstants';
import { Event } from '../../types/store/event';
import { EventActionTypes } from '../actions/eventActions';

const initialState: Event.State = {
  data: {} as Event.Data,
  fetchStatus: FetchConstants.STALLING,
};

export default function eventReducer(state = initialState, action: Event.ActionTypes) {
  switch (action.type) {
    case EventActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case EventActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case EventActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    case EventActionTypes.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
