import React from 'react';
import { Router } from 'react-router-dom';
import history from '../services/history';
import Routes from '../services/routeConfig';
import App from './app';
import { Provider } from 'react-redux';
import { Redux } from '../types/store/redux';

interface Props {
  store: Redux.ApplicationStore;
}

export default function Root(props: Props): React.ReactElement {
  return (
    <Provider store={props.store}>
      <Router history={history}>
        <App routes={Routes} />
      </Router>
    </Provider>
  );
}
