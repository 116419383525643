import './PageLoader.scss';
import React from 'react';

export default function PageLoader() {
  return (
    <div className="page-loader">
      <div className="page-loader__animation">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
