import { Redux } from '../../types/store/redux';

export default class AppSelectors {
  static localization = (state: Redux.RootState) => state.app.localization;

  static username = (state: Redux.RootState) => state.app.userData.username;

  static isSuperuser = (state: Redux.RootState) => state.app.userData.is_superuser;

  static trainerSubjectId = (state: Redux.RootState) => state.app.userData.trainer?.subject;

  static trainerCompetitionId = (state: Redux.RootState) => state.app.userData.trainer?.competition;

  static trainerId = (state: Redux.RootState) => state.app.userData.trainer?.id;

  static currentLicenseType = (state: Redux.RootState) => state.app.currentLicenseType;
}
