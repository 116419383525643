import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { throttle } from 'lodash';
import rootReducer from './reducers/rootReducer';
import { Redux } from '../types/store/redux';
import LocalStorage from '../services/storage/localStorage';

export default function configureStore(): Redux.ApplicationStore {
  const persistedState = LocalStorage.state.get();

  let middlewares = applyMiddleware(thunk);

  if (process.env.NODE_ENV !== 'production') {
    const composeWithDevTools = require('redux-devtools-extension').composeWithDevTools;

    middlewares = composeWithDevTools(middlewares);
  }

  const store = createStore(rootReducer, persistedState, middlewares);

  store.subscribe(
    throttle(() => {
      LocalStorage.state.set({
        app: store.getState().app,
      });
    }, 2000)
  );

  return store;
}
