import React from 'react';
import LocItem from './LocItem';
import LocaleUtils from '../../utils/localeUtils';
import { getState } from '../..';

export default function l(locKey: string, params?: Record<string, any>) {
  return React.createElement(LocItem, { locKey, params });
}

/**
 * Stands for 'localization standalone' which means
 * you can achieve 'plain' localization (string, not wrapped into the React element)
 * from almost every place in code, even from not connected (to Redux store) functions.
 */
export function ls(locKey: string, params?: Record<string, any>) {
  const localization = getState().app.localization;
  const locItem = localization[locKey];

  return LocaleUtils.getProcessedLocItem(locItem, params);
}
