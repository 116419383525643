import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import StoreUtils from '../../utils/storeUtils';

export const SessionActionTypes = StoreUtils.getGenericActionTypes('Session');

export default class SessionActions {
  static getSessions = (competitionId: DataTypes.Id) => (dispatch: Dispatch) => {
    dispatch({ type: SessionActionTypes.FETCH_PENDING });

    return new Promise((resolve, reject) => {
      Api.getSessions(competitionId).then(
        (result: AxiosResponse<DataTypes.SessionVerbose>) => {
          dispatch({
            type: SessionActionTypes.FETCH_SUCCESS,
            payload: result.data,
          });
          resolve();
        },
        (reason) => {
          console.error(reason);
          dispatch({ type: SessionActionTypes.FETCH_FAILURE });
          reject();
        }
      );
    });
  };

  static postSession = (sessionData: DataTypes.Session) => () => {
    return new Promise((resolve, reject) => {
      Api.postSession(sessionData).then(
        (response) => {
          if (response.status === 201) resolve();
        },
        () => reject()
      );
    });
  };

  static putSession = (sessionId: DataTypes.Id, sessionData: Partial<DataTypes.Session>) => () => {
    return new Promise((resolve, reject) => {
      Api.putSession(sessionId, sessionData).then(
        () => resolve(),
        () => reject()
      );
    });
  };

  static deleteSession = (sessionId: DataTypes.Id) => () => {
    return new Promise((resolve, reject) => {
      Api.deleteSession(sessionId).then(
        () => resolve(),
        () => reject()
      );
    });
  };
}
