import FetchConstants from '../../constants/fetchConstants';
import { Nominations } from '../../types/store/nominations';
import { NominationsActionTypes } from '../actions/nominationsActions';

const initialState: Nominations.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function nominationsReducer(state = initialState, action: Nominations.ActionTypes) {
  switch (action.type) {
    case NominationsActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case NominationsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case NominationsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
