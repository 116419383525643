import FetchConstants from '../../constants/fetchConstants';
import { Subjects } from '../../types/store/subjects';
import { SubjectsActionTypes } from '../actions/subjectsActions';

const initialState: Subjects.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
  participants: [],
  participantsFetchStatus: FetchConstants.STALLING,
};

export default function subjectsReducer(state = initialState, action: Subjects.ActionTypes) {
  switch (action.type) {
    case SubjectsActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case SubjectsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload as Subjects.Data,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case SubjectsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    case SubjectsActionTypes.FETCH_PARTICIPATING_PENDING:
      return {
        ...state,
        participantsFetchStatus: FetchConstants.PENDING,
      };
    case SubjectsActionTypes.FETCH_PARTICIPATING_SUCCESS:
      return {
        ...state,
        participants: action.payload as Subjects.ParticipantsData,
        participantsFetchStatus: FetchConstants.SUCCESS,
      };
    case SubjectsActionTypes.FETCH_PARTICIPATING_FAILURE:
      return {
        ...state,
        participantsFetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
