import Api from '../../services/api';
import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { DataTypes } from '../../types/dataTypes';
import StoreUtils from '../../utils/storeUtils';

function handleAthleteEventError(error: AxiosError): { regIsClosed?: boolean } {
  // Assume that competition registration is closed or user is not superuser:
  if (error?.response?.status === 400) {
    return { regIsClosed: true };
  } else {
    return {};
  }
}

export const AthleteActionTypes = {
  ...StoreUtils.getGenericActionTypes('Athlete'),
  CLEAR_DATA: 'Athlete.CLEAR_DATA',
  FETCH_EVENTS_PENDING: 'Athlete.FETCH_EVENTS_PENDING',
  FETCH_EVENTS_SUCCESS: 'Athlete.FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILURE: 'Athlete.FETCH_EVENTS_FAILURE',
};

export default class AthleteActions {
  static getAthlete = (athleteId: string) => (dispatch: Dispatch) => {
    dispatch({ type: AthleteActionTypes.FETCH_PENDING });

    Api.getAthlete(athleteId).then(
      (response: AxiosResponse<DataTypes.ActiveLicense>) => {
        dispatch({
          type: AthleteActionTypes.FETCH_SUCCESS,
          payload: response.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: AthleteActionTypes.FETCH_FAILURE });
      }
    );
  };

  static clearAthlete = () => ({
    type: AthleteActionTypes.CLEAR_DATA,
  });

  static postAthlete = (athleteData: DataTypes.Athlete) => () => {
    return new Promise((resolve: (newAthleteId: string) => void, reject) => {
      Api.postAthlete(athleteData).then(
        (response) => {
          if (response.status === 200 || response.status === 201) {
            resolve(response.data.id);
          }
        },
        (error) => {
          console.error(error);
          reject(error.response?.data);
        }
      );
    });
  };

  static patchAthlete = (
    athleteId: DataTypes.Id,
    athleteData: Partial<DataTypes.Athlete>
  ) => () => {
    return new Promise((resolve: (athleteData: DataTypes.Athlete) => void, reject) => {
      Api.patchAthlete(athleteId, athleteData).then(
        (response: AxiosResponse<DataTypes.Athlete>) => resolve(response.data),
        () => reject()
      );
    });
  };

  static getAthleteEvents = (athleteId: DataTypes.Id, competitionId: DataTypes.Id) => (
    dispatch: Dispatch
  ) => {
    dispatch({ type: AthleteActionTypes.FETCH_EVENTS_PENDING });

    Api.getAthleteEvents(athleteId, competitionId).then(
      (response: AxiosResponse<DataTypes.AthleteDistance[]>) => {
        dispatch({
          type: AthleteActionTypes.FETCH_EVENTS_SUCCESS,
          payload: response.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: AthleteActionTypes.FETCH_EVENTS_FAILURE });
      }
    );
  };

  static postAthleteDistanceResult = (data: DataTypes.AthleteDistanceResult) => () => {
    return new Promise((resolve, reject) => {
      Api.postAthleteDistanceResult(data).then(
        (response) => {
          if (response.status === 201 || response.status === 200) {
            resolve();
          }
        },
        () => reject()
      );
    });
  };

  static patchAthleteEventResult = (
    eventId: DataTypes.Id,
    eventData: Partial<DataTypes.AthleteLinkEvent>
  ) => () => {
    return new Promise((resolve, reject: (status: { regIsClosed?: boolean }) => void) => {
      Api.patchAthleteEventResult(eventId, eventData).then(
        () => resolve(),
        (error) => reject(handleAthleteEventError(error))
      );
    });
  };

  static deleteAthleteEventResult = (eventId: DataTypes.Id) => () => {
    return new Promise((resolve, reject) => {
      Api.deleteAthleteEventResult(eventId).then(
        () => resolve(),
        (error) => reject(handleAthleteEventError(error))
      );
    });
  };
}
