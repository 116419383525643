import history from './history';
import RouterUtils from '../utils/routerUtils';
import { RouteService } from '../types/services/routeService';
import { UtilTypes } from '../types/utilTypes';

export default class Router {
  static navigateTo(
    path: RouteService.RouterPathsKey,
    params?: Partial<RouteService.RouteParams>,
    searchParams?: UtilTypes.SearchParams
  ) {
    const processedPath = RouterUtils.getProcessedPath(path, params);
    const processedSearch = RouterUtils.getProcessedSearch(searchParams);

    history.push({
      pathname: processedPath,
      search: processedSearch,
    });
  }

  static appendSearchQuery(searchParams: UtilTypes.SearchParams) {
    const processedSearch = RouterUtils.getProcessedSearch(searchParams);

    history.push({ search: processedSearch });
  }

  static goBack() {
    history.goBack();
  }
}
