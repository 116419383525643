import FetchConstants from '../../constants/fetchConstants';
import { Clubs } from '../../types/store/clubs';
import { ClubsActionTypes } from '../actions/clubsActions';

const initialState: Clubs.State = {
  data: [],
  fetchStatus: FetchConstants.STALLING,
};

export default function clubsReducer(state = initialState, action: Clubs.ActionTypes) {
  switch (action.type) {
    case ClubsActionTypes.FETCH_PENDING:
      return {
        ...state,
        fetchStatus: FetchConstants.PENDING,
      };
    case ClubsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchStatus: FetchConstants.SUCCESS,
      };
    case ClubsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: FetchConstants.FAILURE,
      };
    default:
      return state;
  }
}
