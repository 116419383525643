import RouterPaths from '../../constants/routerPaths';
import CompetitionActions from '../../store/actions/competitionActions';
import React from 'react';
import { BreadPath } from './Breadcrumbs';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { RouteService } from '../../types/services/routeService';
import { useDispatch, useSelector } from 'react-redux';
import AppSelectors from '../../store/selectors/appSelectors';
import CompetitionSelectors from '../../store/selectors/competitionSelectors';
import RouterUtils from '../../utils/routerUtils';
import EventActions from '../../store/actions/eventActions';
import EventSelectors from '../../store/selectors/eventSelectors';
import CustomHooks from '../../utils/customHooks';

export default class BreadcrumbsHooks {
  static usePathBread() {
    const l = CustomHooks.useLocalization();

    const dispatch = useDispatch();

    const formatEventTitle = CustomHooks.useEventTitleFormat();

    const isSuperuser = useSelector(AppSelectors.isSuperuser);
    const competitionName = useSelector(CompetitionSelectors.competitionName);
    const event = useSelector(EventSelectors.data);

    const eventName = formatEventTitle(event);

    const location = useLocation<RouteService.LocationState>();
    const match = useRouteMatch<RouteService.RouteParams>();

    const [pathBread, setPathBread] = React.useState<BreadPath[]>([]);

    React.useEffect(() => {
      if (
        match.path.includes(RouterPaths.competitionPage) ||
        match.path.includes(RouterPaths.freeCompetitionPage)
      ) {
        if (competitionName == null || competitionName === '') {
          dispatch(CompetitionActions.getCompetition(match.params.competitionId));
        }
      }
    }, [dispatch, competitionName, match.path, match.params.competitionId]);

    React.useEffect(() => {
      if (match.path.includes(RouterPaths.distancePage)) {
        if (eventName == null || eventName === '') {
          dispatch(EventActions.getEvent(match.params.distanceId));
        }
      }
    }, [dispatch, eventName, match.path, match.params.distanceId]);

    React.useEffect(() => {
      const _pathBread: BreadPath[] = [
        {
          text: l('breadcrumbs/index_page'),
          link: RouterPaths.indexPage,
        },
      ];

      if (match.path.includes(RouterPaths.profilePage) && isSuperuser) {
        _pathBread.push({
          text: l('breadcrumbs/profile_page'),
          link: RouterPaths.profilePage,
        });
      }

      if (match.path.includes(RouterPaths.licensePage)) {
        _pathBread.push({
          text: l('breadcrumbs/license_page'),
          link: RouterPaths.licensePage,
        });
      }

      if (match.path.includes(RouterPaths.competitionPage)) {
        let link = RouterUtils.getProcessedPath(RouterPaths.competitionPage, {
          competitionId: match.params.competitionId,
        });

        let tabQuery = ''; // TODO: use RouterUtils instead?
        if (location.state?.fromStartList) {
          tabQuery = '?tab=start-list';
        } else if (match.path.includes(':distanceId')) {
          tabQuery = '?tab=program';
        } else if (
          match.path.includes(RouterPaths.athletePage) ||
          match.path.includes(RouterPaths.athleteCreatePage)
        ) {
          tabQuery = '?tab=bids';
        }

        _pathBread.push({
          text: competitionName || '...',
          link: link + tabQuery,
        });
      }

      if (match.path.includes(RouterPaths.distancePage)) {
        const link = RouterUtils.getProcessedPath(RouterPaths.distancePage, {
          competitionId: match.params.competitionId,
          distanceId: match.params.distanceId,
        });

        _pathBread.push({
          text: eventName || '...',
          link: link,
        });
      }

      if (match.path.includes(':athleteId')) {
        _pathBread.push({ text: l('breadcrumbs/athlete_page'), link: '' });
      }

      if (match.path.includes(RouterPaths.freeCompetitionPage)) {
        _pathBread.push({ text: competitionName || '...', link: '' });
      }

      setPathBread(_pathBread);
    }, [l, isSuperuser, competitionName, eventName, match.path, match.params, location.state]);

    return pathBread;
  }
}
