import CommonUtils from './commonUtils';

export default class LocaleUtils {
  static getProcessedLocItem(locItem: string | undefined, params: Record<string, any> = {}) {
    if (locItem === undefined) return '';
    if (Object.keys(params).length === 0) return locItem;

    const matches = CommonUtils.getAllMatchesByRegExp(/%(\w+)/g, locItem);

    if (matches.length === 0) return locItem;

    let formattedLocItem = locItem;
    matches.forEach((match) => {
      formattedLocItem = formattedLocItem.replace(match[0], params![match[1]]);
    });

    return formattedLocItem;
  }
}
