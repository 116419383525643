import Api from '../../services/api';
import { Dispatch } from 'redux';
import StoreUtils from '../../utils/storeUtils';

export const CompetitionsActionTypes = StoreUtils.getGenericActionTypes('Competitions');

export default class CompetitionsActions {
  static getCompetitions = () => (dispatch: Dispatch) => {
    dispatch({ type: CompetitionsActionTypes.FETCH_PENDING });

    Api.getCompetitions().then(
      (result) => {
        dispatch({
          type: CompetitionsActionTypes.FETCH_SUCCESS,
          payload: result.data,
        });
      },
      (reason) => {
        console.error(reason);
        dispatch({ type: CompetitionsActionTypes.FETCH_FAILURE });
      }
    );
  };
}
