import { combineReducers } from 'redux';
import competitionsReducer from './competitionsReducer';
import appReducer from './appReducer';
import competitionTypesReducer from './competitionTypesReducer';
import athletesReducer from './athletesReducer';
import competitionReducer from './competitionReducer';
import clubsReducer from './clubsReducer';
import subjectsReducer from './subjectsReducer';
import ranksReducer from './ranksReducer';
import eventsReducer from './eventsReducer';
import athleteReducer from './athleteReducer';
import sessionReducer from './sessionReducer';
import nominationsReducer from './nominationsReducer';
import categoriesReducer from './categoriesReducer';
import gendersReducer from './gendersReducer';
import eventReducer from './eventReducer';

const rootReducer = combineReducers({
  app: appReducer,
  athlete: athleteReducer,
  athletes: athletesReducer,
  categories: categoriesReducer,
  clubs: clubsReducer,
  competition: competitionReducer,
  competitions: competitionsReducer,
  competitionTypes: competitionTypesReducer,
  event: eventReducer,
  events: eventsReducer,
  genders: gendersReducer,
  nominations: nominationsReducer,
  ranks: ranksReducer,
  session: sessionReducer,
  subjects: subjectsReducer,
});

export default rootReducer;
