/**
 * @author Artem Piskarev
 *
 * RouterPaths describes routes of the application.
 * Routes can be specified for all the pages in use.
 * One must specify routes [only] for pages (src/pages/).
 *
 * Format: 'key - value' (examples):
 * - examplePage: '/example'
 * - transactionPage: '/transactions/:transactionId/main'
 *
 * Keys (like ':someImportantVariable') are being processed in redirect function (not this file).
 *
 * Please proceed to RouteConfig (src/services/routeConfig.ts)
 * in order to finish configuring newly added paths.
 *
 * Also you should add route params to RouteParams interface (src/types/services/routeService.d.ts).
 */

const RouterPaths = {
  indexPage: '/',
  profilePage: '/profile',
  licensePage: '/profile/license',
  competitionPage: '/profile/competition/:competitionId',
  distancePage: '/profile/competition/:competitionId/distance/:distanceId',
  athletePage: '/profile/competition/:competitionId/athlete/:athleteId',
  athleteCreatePage: '/profile/competition/:competitionId/athlete/create',
  athletePageFromDistancePage:
    '/profile/competition/:competitionId/distance/:distanceId/athlete/:athleteId',
  freeCompetitionPage: '/competitions/:competitionId',
  page404: '*',
};

export default RouterPaths;
